import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase';
import { Config } from 'ng-otp-input/lib/models/config';
import {
  CountryISO, SearchCountryField
} from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { ContactUsService } from '../../../services/contactUsService/contact-us.service';
import { WindowService } from '../../../services/windowService/window.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  public contactForm: FormGroup;
  public loading = false;
  windowRef: any;
  showOtpScreen = false;

  SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India];

  otpConfig: Config = {
    length: 6,
    allowNumbersOnly: true,
  };

  verificationCode: number;
  user: any;

  contactDetails = {
    email: 'contact@zipomega.com',
    phone: '+91 7003798498',
    city: 'Kolkata, India'
  };

  constructor(
    public formBuilder: FormBuilder,
    private win: WindowService,
    private contactUsService: ContactUsService,
    private toastr: ToastrService,
    private analytics: AngularFireAnalytics
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.loadRecapcha();
  }

  ngOnInit(): void {
    this.createContactForm();
  }

  ngAfterViewInit(): void {
    this.loadRecapcha();
    // this.getFireStoreData();
  }

  ngOnDestroy() {
    // this.windowRef = null;
  }

  async contactFormSubmit() {
    if (!this.contactForm.valid) {
      this.analytics.logEvent('Contact_Form_Invalid_Submit_Event', {
        contactFormInvalid: true,
      });
      this.contactForm.markAllAsTouched();
    } else {
      this.analytics.logEvent('Contact_Form_Valid_Submit_Event', {
        contactFormValid: true,
      });
      this.loading = true;
      const saveResponseData = await this.saveContactData(this.contactForm.value);
      this.loading = false;
      this.sendOtp();
    }
  }

  onOtpChange(data: any) {
    this.verificationCode = data;
  }

  sendOtp() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const phoneNumber = this.contactForm.get('phoneNumber').value.e164Number;
    if (appVerifier) {
      firebase.default.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(result => {
          this.showOtpScreen = true;
          this.toastr.success('Plese Verify.', 'Otp send successfully');
          this.toastr.success('We Will contact you soon.', 'Thank you.');
          this.windowRef.confirmationResult = result;
          this.analytics.logEvent('Contact_Form_Otp_Send_Event', {
            otpSend: true,
          });
        })
        .catch(error => {
          this.analytics.logEvent('Contact_Form_Otp_Send_Error_Event', {
            otpSend: true,
          });
          console.log(error);
        });
    } else {
      this.toastr.error(``, 'Please verify recaptcha.');
    }
  }

  verifyPhoneOtp() {
    if (this.verificationCode.toString().length === 6) {
      this.windowRef.confirmationResult
        .confirm(this.verificationCode)
        .then(result => {
          this.analytics.logEvent('Contact_Form_Otp_Verify_Success_Event', {
            verify: true,
          });
          this.user = result.user;
          this.toastr.success('Thank you. We Will contact you soon.', 'Otp verified successfully');
          this.contactForm.reset();
          this.showOtpScreen = false;
        })
        .catch(error => {
          // console.log(error, 'Incorrect code entered?');
          this.analytics.logEvent('Contact_Form_Otp_Verify_Error_Event', {
            verify: false,
          });
          // this.toastr.error(`${error.message}`, 'Wrong Otp.');
          this.toastr.error(`Please enter the correct otp.`, 'Wrong Otp.');
        });
    } else {
      this.analytics.logEvent('Contact_Form_Empty_Otp_Submit_Event', {
        verify: false,
      });
      this.toastr.error(`Please enter the correct otp.`, 'Wrong Otp.');
    }
  }

  private createContactForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      verified: [false],
      contacted: [false],
      closed: [false]
    });
  }

  private getFireStoreData() {
    console.log('calling');

    // tslint:disable-next-line: deprecation
    this.contactUsService.getContact().subscribe((data) => {
      console.log('test data1', data);
      data.docs.forEach((doc) => {
        console.log('test data', doc);
      });
      // console.log('test data1', data);
    });
  }

  async saveContactData(body) {
    return await this.contactUsService.addContactData(body);
  }

  private loadRecapcha() {
    // console.log('init');
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.default.auth.RecaptchaVerifier('contact-btn'
      ,
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.contactFormSubmit();
        }
      });
    this.windowRef.recaptchaVerifier.render();
  }

}

