import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLandingPageComponent } from './main-landing-page/main-landing-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ErrorComponent } from './share/error/error.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { ReadyMadeComponent } from './pages/ready-made/ready-made.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';

const routes: Routes = [
  {
    path: '',
    component: MainLandingPageComponent,
    children: [
      {
        path: '',
        component: HomePageComponent,
        data: {
          title: 'Home',
          des: `Zipomega is providing services like web design, web development, web applications, E-commerce, SEO websites development,
          custom web apps, ios android mobile app development, cyber security,
          AI, Machine learning services from kolkata, India.
          We are a team of young, agile, and highly professional people
          working towards making technology solve problems.
          We are on a mission of giving you the best technology to ensure that you are in control.
          We are dedicated to developing convenient technology to empower all.`
        }
      },
      {
        path: 'services',
        component: ServicesPageComponent,
        data: {
          title: 'Services',
          des: `Zipomega is providing services like web design, web development, web applications, E-commerce, SEO websites development,
          custom web apps, ios android mobile app development, cyber security,
          AI, Machine learning services from kolkata, India.
          We are a team of young, agile, and highly professional people
          working towards making technology solve problems.
          We are on a mission of giving you the best technology to ensure that you are in control.
          We are dedicated to developing convenient technology to empower all.`
        }
      },
      // {
      //   path: 'ready-made-scripts',
      //   component: ReadyMadeComponent,
      //   data: {
      //     title: 'Ready Made Scripts'
      //   }
      // },
      // {
      //   path: 'products',
      //   component: ReadyMadeComponent,
      //   data: {
      //     title: 'Our Products'
      //   }
      // },
      {
        path: 'portfolio',
        component: PortfolioComponent,
        data: {
          title: 'Portfolio'
        }
      },
      {
        path: 'contact',
        component: ContactComponent,
        data: {
          title: 'Contact'
        }
      },
      {
        path: '**',
        component: ErrorComponent,
        data: {
          title: 'Error'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainLandingPageRoutingModule { }
