import { Component, OnInit, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))]),
  ],
  encapsulation: ViewEncapsulation.None
})

// @HostListener('window:resize', ['$event'])

export class HeaderComponent implements OnInit {
  fadeInDown: any;
  public innerWidth: any;
  navDown = false;
  collapsed = true;
  home = false;

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.router.events.subscribe((event) => {
      this.home = this.router.url === '/';
      // if(event['url']) {
      //     console.log(event['url']);
      // }
    });

  }

  // onResize(event) {
  //   this.innerWidth = window.innerWidth;
  // }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50) {
      document.getElementById('navBar').classList.add('menu_fixed');
      this.navDown = true;
    } else {
      document.getElementById('navBar').classList.remove('menu_fixed');
      this.navDown = false;
    }
  }
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

}
