<div class="ready-made container">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-4 mt-5" *ngFor="let data of readyMadeScriptList">
      <div class="box mat-elevation-z24">
          <div class="img-holder">
              <img class="img-fluid img-thumbnail" [src]="data.imgUrl" alt="">
          </div>
        <div class="text-center mt-2">
          <!-- // TODO: add img slider -->
          <h3 class="title">
            {{data.title}}
          </h3>
          <p class="description" *ngIf="data.showDescription">
            {{data.description}}
          </p>
          <div class="">
            <!-- <a class="btn-grad" href="javaScript:void(0)" routerLink="/contact">Learn More</a> -->
            <a class="btn-grad text-center" href="javaScript:void(0)" routerLink="/contact">Book a Demo</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
