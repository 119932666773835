<div class="particles-js" id="particles-js"></div>
<app-banner></app-banner>
<!-- <app-tech-highlight></app-tech-highlight> -->
<app-tech-services></app-tech-services>
<!-- <div class="particles-js" id="particles-js-counter"></div> -->
<app-counter></app-counter>
<app-why-us></app-why-us>
<!-- <app-main-services></app-main-services> -->
<!-- <app-pricing></app-pricing> -->
<!-- <app-quotes></app-quotes> -->
<app-how-it-works></app-how-it-works>
<!-- <app-about></app-about> -->

<app-contact-us></app-contact-us>