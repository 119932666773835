<div class="container">
  <div class="service-highlight row">
    <div class="col-6 col-md-3" *ngFor="let service of servicesList">
      <div class="box mat-elevation-z18 bg-white">
        <div class="col-12 mb-3">
          <span class="title">
            {{service.title}}
          </span>
          <i *ngIf="service.isIcon" class="icon {{service.icon}}"></i>
        </div>
        <div class="col-12 description">
          {{service.description}}
        </div>
      </div>
    </div>
  </div>
</div>
