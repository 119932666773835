<section class="banner_part">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-5">
                <div [@fadeInLeftOnEnter] [@fadeOutLeftOnLeave] class="banner_img d-none d-lg-block text-light">
                    <img src={{imgUrl}} alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="banner_text">
                    <div class="banner_text_iner">
                        <ngx-typed-js [strings]='typedData' [typeSpeed]="40" [loop]='true'>
                            <h1 class="typing"></h1>
                        </ngx-typed-js>
                        <p>
                            {{subText}}
                        </p>
                        <a data-aos="fade-up" href="javaScript:void(0)" routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="btn-grad">{{buttonText}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="../../assets/img/animate_icon/Ellipse_7.png" alt="" class="feature_icon_1 custom-animation1">
    <img src="../../assets/img/animate_icon/Ellipse_8.png" alt="" class="feature_icon_2 custom-animation2">
    <img src="../../assets/img/animate_icon/Ellipse_1.png" alt="" class="feature_icon_3 custom-animation3">
    <img src="../../assets/img/animate_icon/Ellipse_2.png" alt="" class="feature_icon_4 custom-animation4">
    <img src="../../assets/img/animate_icon/Ellipse_3.png" alt="" class="feature_icon_5 custom-animation5">
    <img src="../../assets/img/animate_icon/Ellipse_4.png" alt="" class="feature_icon_6 custom-animation6">
</section>