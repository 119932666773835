import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getActiveServiceList } from '../../../services/tech-services/tech-services';

@Component({
  selector: 'app-tech-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  servicesList = getActiveServiceList();
  @Input() public checkAllServices = `Check Other Services`;
  @Input() public showCheckAllServices = true;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.showCheckAllServices = this.router.url !== '/services';
  }

}
