import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { MainLandingPageRoutingModule } from './main-landing-page-routing.module';
import { MainLandingPageComponent } from './main-landing-page/main-landing-page.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FeaturesComponent } from './pages/features/features.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutComponent } from './share/about/about.component';
import { BannerComponent } from './share/banner/banner.component';
import { BarComponent } from './share/bar/bar.component';
import { BreadCrumbComponent } from './share/bread-crumb/bread-crumb.component';
import { ContactUsComponent } from './share/contact-us/contact-us.component';
import { CounterComponent } from './share/counter/counter.component';
import { ErrorComponent } from './share/error/error.component';
import { FooterComponent } from './share/footer/footer.component';
import { GetEstimationComponent } from './share/get-estimation/get-estimation.component';
import { HeaderComponent } from './share/header/header.component';
import { HowItWorksComponent } from './share/how-it-works/how-it-works.component';
import { MainServicesComponent } from './share/main-services/main-services.component';
import { PricingComponent } from './share/pricing/pricing.component';
import { QuotesComponent } from './share/quotes/quotes.component';
import { WhyUsComponent } from './share/why-us/why-us.component';
import { TechHighlightComponent } from './share/tech-highlight/tech-highlight.component';
import { ServicesComponent } from './share/tech-services/services.component';
import { AgileStepsComponent } from './share/how-it-works/agile-steps/agile-steps.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { ReadyMadeScriptComponent } from './share/ready-made-script/ready-made-script.component';
import { ReadyMadeComponent } from './pages/ready-made/ready-made.component';
import { ImgSliderComponent } from './share/img-slider/img-slider.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgOtpInputModule } from 'ng-otp-input';
import { LunchProjectComponent } from './share/lunch-project/lunch-project.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';



const COMPONENTS = [
  BarComponent,
  BreadCrumbComponent,
  ContactUsComponent,
  ContactComponent,
  ErrorComponent,
  HeaderComponent,
  FooterComponent,
  BannerComponent,
  HomePageComponent,
  WhyUsComponent,
  MainServicesComponent,
  CounterComponent,
  PricingComponent,
  QuotesComponent,
  HowItWorksComponent,
  AboutComponent,
  MainLandingPageComponent,
  FeaturesComponent,
  GetEstimationComponent,
  TechHighlightComponent,
  ServicesComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    AgileStepsComponent,
    ServicesPageComponent,
    ReadyMadeScriptComponent,
    ReadyMadeComponent,
    ImgSliderComponent,
    LunchProjectComponent,
    PortfolioComponent
  ],
  imports: [
    CommonModule,
    MainLandingPageRoutingModule,
    CarouselModule,
    NgxTypedJsModule,
    // ParticlesModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    NgOtpInputModule
  ],
})
export class MainLandingPageModule { }
