<section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center mb-5 pb-5">
      <div class="col-md-7 text-center heading-section ftco-animate">
        <h2 class="mb-4 text-white">How it works</h2>
        <span class="subheading text-white">
          {{subText}}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 nav-link-wrap mb-2 pb-md-5 pb-sm-1 ftco-animate">
        <div class="nav ftco-animate nav-pills justify-content-center text-center" id="v-pills-tab" role="tablist"
          aria-orientation="vertical">
          <a class="nav-link active mb-sm-1" id="v-pills-requirements-tab" data-toggle="pill" href="javaScript:void(0)"
            (click)="active = 0" role="tab" aria-controls="v-pills-requirements" aria-selected="true">Discussion</a>

          <a class="nav-link mb-sm-1" id="v-pills-implementation-tab" data-toggle="pill" href="javaScript:void(0)"
            (click)="active = 1" role="tab" aria-controls="v-pills-implementation"
            aria-selected="false">Strategy</a>

          <a class="nav-link mb-sm-1" id="v-pills-deploy-tab" data-toggle="pill" href="javaScript:void(0)" role="tab"
            (click)="active = 2" aria-controls="v-pills-deploy" aria-selected="false">UI / UX</a>

          <a class="nav-link mb-sm-1" id="v-pills-maintenance-tab" data-toggle="pill" href="javaScript:void(0)" role="tab"
            (click)="active = 3" aria-controls="v-pills-maintenance" aria-selected="false">Implementation</a>
            <a class="nav-link mb-sm-1" id="v-pills-maintenance-tab" data-toggle="pill" href="javaScript:void(0)" role="tab"
            (click)="active = 4" aria-controls="v-pills-maintenance" aria-selected="false">Testing</a>
            <a class="nav-link mb-sm-1" id="v-pills-maintenance-tab" data-toggle="pill" href="javaScript:void(0)" role="tab"
            (click)="active = 5" aria-controls="v-pills-maintenance" aria-selected="false">Lanuch & Support</a>
        </div>
      </div>
      <div class="col-md-12 align-items-center ftco-animate">

        <div class="tab-content ftco-animate" id="v-pills-tabContent">
          <ng-container *ngFor="let steps of stepsList; let index = index">
              <app-agile-steps
              *ngIf="active === index"
              [title]="steps.title"
              [description]="steps.description"
              [imagePath]="steps.imagePath"
              ></app-agile-steps>
          </ng-container>
          <!-- <app-agile-steps *ngIf="active === 0" title="Discussion & Brainstorming" description="Before developing the product, 
          we like to understand the business background, 
          discuss it with the stake holders to generate ideas and objective 
          so that we can create a product that matches the client needs and wants."
          imagePath="../../assets/img/undraw_laravel_and_vue_59tp.svg"
          ></app-agile-steps>
          <app-agile-steps *ngIf="active === 1" title="Strategy & Prototyping" description="We make a preliminary 
          design or model of our idea that we discussed and strategize that early sample on how 
          to achieve this goal in the given time. This would give almost the complete picture of the 
          flow of the product to the client."
          imagePath="../../assets/img/undraw_visual_data_b1wx.svg"
          ></app-agile-steps>
          <app-agile-steps *ngIf="active === 2" title="UI / UX" description="Once we get the idea and a complete solid 
          understanding of what needs to be developed, 
          we design it accordingly to show that how it would look, 
          work and feel to the customer."
          imagePath="../../assets/img/undraw_visual_data_b1wx.svg"
          ></app-agile-steps>
          <app-agile-steps *ngIf="active === 3" title="Implementation" description="The plans and strategies 
          that we have discussed, we turn them into actions in order to accomplish our objective and goals."
          imagePath="../../assets/img/undraw_business_plan_5i9d.svg"
          ></app-agile-steps>
          <app-agile-steps *ngIf="active === 4" title="Testing" description="After developing the desired product, 
          we test it in order to make sure the product is developed properly and according to the requirements."
          imagePath="../../assets/img/undraw_visual_data_b1wx.svg"
          ></app-agile-steps>
          <app-agile-steps *ngIf="active === 5" title="Lanuch & Support" description="Even after 
          the product is delivered to our clients we don’t fold our hands and we don’t try to get rid
          of our responsibilities. We are always there to maintain the application even after the development."
          imagePath="../../assets/img/undraw_visual_data_b1wx.svg"
          ></app-agile-steps> -->

        </div>
      </div>
    </div>
  </div>
</section>
