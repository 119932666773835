const servicesList = [
    {
        title: 'Web development',
        description: 'We offer innovative, agile & competitive services for custom software & application development.',
        isReadMore: false,
        readMoreLink: '',
        isIcon: true,
        icon: 'far fa-window-maximize',
        show: true,
    },
    {
        title: 'Mobile App development',
        description: 'The best mobile app development solutions for diverse needs.',
        isReadMore: false,
        readMoreLink: '',
        isIcon: true,
        icon: 'fas fa-mobile-alt',
        show: true,
    },
    {
        title: 'Cloud Services',
        description: 'We provide agnostic, carrier-neutral, and globally deployable cloud native solutions',
        isReadMore: false,
        readMoreLink: '',
        isIcon: true,
        icon: 'fab fa-cloudversify',
        show: true,
    },
    {
        title: 'UI/UX Design',
        // tslint:disable-next-line: max-line-length
        description: 'You want your visitors to be your buyers and your buyers to stay with you. Our UX/UI helps you achieve that. We create interactive, user-friendly and appealing designs that speak to your customers and help generate sales!',
        isReadMore: false,
        readMoreLink: '',
        isIcon: true,
        icon: 'far fa-object-ungroup',
        show: false,
    },
    {
        title: 'Digital Marketing',
        // tslint:disable-next-line: max-line-length
        description: 'Get on top of Google search results,AdWords. Leverage Social Media to create buzz with posts and paid campaigns. We plan and execute laser-focused campaigns with high RoI.',
        isReadMore: false,
        readMoreLink: '',
        isIcon: true,
        icon: 'fas fa-bullhorn',
        show: false,
    },
    {
        title: 'NexGen Services',
        description: 'We provide nexGen services starting from IoT to blockchain to AR/VR as per the requirement.',
        isReadMore: false,
        readMoreLink: '',
        isIcon: true,
        icon: 'fas fa-vr-cardboard',
        show: true,
    },
    {
        title: 'Cyber Security Services',
        description: 'Packaged and highly effective cyber security services to keep your company secured.',
        isReadMore: false,
        readMoreLink: '',
        isIcon: true,
        icon: 'fas fa-shield-alt',
        show: true,
    },
    {
        title: 'AI Solutions',
        description: 'Innovative and Customized Solutions with AI deployment as well as pathways for novel solutions.',
        isReadMore: false,
        readMoreLink: '',
        isIcon: true,
        icon: 'fas fa-robot',
        show: true,
    },
];


export const getActiveServiceList = () => {
    return servicesList.filter(service => service.show);
};
