  <!-- ================ contact section start ================= -->
  <section class="contact-section section_padding">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="contact-title text-white">Get in Touch</h2>
        </div>
        <div class="col-lg-8">
          <form class="form-contact contact_form" [formGroup]="contactForm">
            <div class="row" *ngIf="!showOtpScreen">
              <div class="col-sm-6">
                <div class="form-group">
                  <input class="form-control" name="name" id="name" type="text" formControlName="name"
                    placeholder='Enter your name'>
                  <p class="text-danger"
                    *ngIf="contactForm.get('name').touched && contactForm.get('name').hasError('required')">
                    Come on, you have a name, don't you?
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input class="form-control" name="email" id="email" type="email" formControlName="email"
                    placeholder='Enter email address'>
                  <p class="text-danger"
                    *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('required')">
                    Come on, you have a email, don't you?
                  </p>
                  <p class="text-danger"
                    *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('email')">
                    Come on, it's a email. Please enter the correct email address.
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <ngx-intl-tel-input [cssClass]="'form-control w-100 text-white'" [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="true" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true"
                    [selectedCountryISO]="CountryISO.India"
                    name="phone" formControlName="phoneNumber"
					[customPlaceholder]="'7003798498'"
					>
                  </ngx-intl-tel-input>
                  <p class="text-danger"
                    *ngIf="contactForm.get('phoneNumber').touched && contactForm.get('phoneNumber').invalid">
                    Come on, you have a phone number, don't you?
                  </p>
                  <!-- <input class="form-control" name="phoneNumber" id="phoneNumber" type="text"
                    formControlName="phoneNumber" placeholder='Enter Phone Number'> -->
                  <!-- <p class="text-danger"
                    *ngIf="contactForm.get('phoneNumber').touched && contactForm.get('phoneNumber').hasError('required')">
                    Come on, you have a phone number, don't you?
                  </p> -->
                  <p class="text-danger"
                    *ngIf="contactForm.get('phoneNumber').touched && contactForm.get('phoneNumber').hasError('maxlength')">
                    Come on, you have a phone number cann't have more than 12 numbers. Right?
                  </p>
                  <p class="text-danger"
                    *ngIf="contactForm.get('phoneNumber').touched && contactForm.get('phoneNumber').hasError('minlength')">
                    Come on, you have a phone number that must have 8 numbers. Right?
                  </p>
                  <p class="text-danger"
                    *ngIf="contactForm.get('phoneNumber').touched && contactForm.get('phoneNumber').hasError('pattern')">
                    Come on, It's a phone number, it cann't have some letters.
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <input class="form-control" name="subject" id="subject" type="text" formControlName="subject"
                    placeholder='Enter Subject'>
                  <p class="text-danger"
                    *ngIf="contactForm.get('subject').touched && contactForm.get('subject').hasError('required')">
                    Come on, you have a subject, don't you?
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9"
                    formControlName="message" placeholder='Enter Message'></textarea>
                  <p class="text-danger"
                    *ngIf="contactForm.get('message').touched && contactForm.get('message').hasError('required')">
                    um...yea, you have to write something to send this form.
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div id="recaptcha-container"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-4" *ngIf="showOtpScreen">
                <h2 class="text-white">Verify your phone number. 
                  <br>
                  Please enter the otp:</h2>
                <ng-otp-input  (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
              </div>
              <div class="form-group mt-3">
                <button *ngIf="!showOtpScreen" class="button button-contactForm btn_1" id="contact-btn">
                  <span *ngIf="!loading">{{'Send Message' }}</span>
                  <i class="fas fa-arrow-right" *ngIf="!loading"></i>
                  <div *ngIf="loading" class="spinner-border" role="status" style="margin: 0 auto;">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
                <button *ngIf="showOtpScreen" (click)="verifyPhoneOtp()" class="button button-contactForm btn_1">
                  <span *ngIf="!loading">{{'Verify Otp'}}</span>
                  <i class="fas fa-arrow-right" *ngIf="!loading"></i>
                  <div *ngIf="loading" class="spinner-border" role="status" style="margin: 0 auto;">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>  
          </form>
        </div>
        <div class="col-lg-4 d-md-flex d-sm-flex d-lg-block">
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-home"></i></span>
            <div class="media-body">
              <h3 class="text-white">{{contactDetails.city}}</h3>
              <!-- <p></p> -->
            </div>
          </div>
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
            <div class="media-body">
              <a href="tel:+917003798498">
                <h3 class="text-white"> +91 70037 984 98 </h3>
              </a>
              <p class="text-white">Mon to Sat 9am to 9pm</p>
            </div>
          </div>
          <div class="media contact-info">
            <span class="contact-info__icon"><i class="ti-email"></i></span>
            <div class="media-body">
              <a href="mailto:contact@zipomega.com">
                <h3 class="text-white">contact@zipomega.com</h3>
              </a>
              <p class="text-white">Send us your query anytime!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
