import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  subText = `To ensure that each of our projects gets nothing but the best,
  we make Understanding the needs and requirements of your business is our top priority,
  and we believe in incorporating solutions that are efficient and effective`;

  subTextAlternate = `We follow agile methodology so our clients don’t need to wait for a huge time to see their products. After
  each sprint they can check the work and they can launch the product in the market while we develop the new
  features for the application.`;

  active = 0;

  stepsList = [
    {
      title: `Discussion & Brainstorming`,
      description: `Before developing the product, 
      we like to understand the business background, 
      discuss it with the stake holders to generate ideas and objective 
      so that we can create a product that matches the client needs and wants.`,
      imagePath: `../../assets/img/our-strategy.svg`
    },
    {
      title: `Strategy & Prototyping`,
      description: `We make a preliminary 
      design or model of our idea that we discussed and strategize that early sample on how 
      to achieve this goal in the given time. This would give almost the complete picture of the 
      flow of the product to the client.`,
      imagePath: `../../assets/img/strategy.svg`
    },
    {
      title: `UI / UX`,
      description: `Once we get the idea and a complete solid 
      understanding of what needs to be developed, 
      we design it accordingly to show that how it would look, 
      work and feel to the customer.`,
      imagePath: `../../assets/img/design.svg`
    },
    {
      title: `Implementation`,
      description: `The plans and strategies 
      that we have discussed, we turn them into actions in order to accomplish our objective and goals.`,
      imagePath: `../../assets/img/programming.svg`
    },
    {
      title: `Testing`,
      description: `After developing the desired product, 
      we test it in order to make sure the product is developed properly and according to the requirements.`,
      imagePath: `../../assets/img/testing.svg`
    },
    {
      title: `Lanuch & Support`,
      description: `Even after 
      the product is delivered to our clients we don’t fold our hands and we don’t try to get rid
      of our responsibilities. We are always there to maintain the application even after the development.`,
      imagePath: `../../assets/img/lanuch.svg`
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
