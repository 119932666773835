import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ready-made',
  templateUrl: './ready-made.component.html',
  styleUrls: ['./ready-made.component.scss']
})
export class ReadyMadeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
