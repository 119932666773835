import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReadyMadeScriptList } from '../../../services/readyMadeScrip/readyMadeScript';

@Component({
  selector: 'app-ready-made-script',
  templateUrl: './ready-made-script.component.html',
  styleUrls: ['./ready-made-script.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReadyMadeScriptComponent implements OnInit {

  readyMadeScriptList = ReadyMadeScriptList;

  constructor() { }

  ngOnInit(): void {
  }

}
