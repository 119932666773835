<section class="ftco-section ftco-counter img" id="section-counter">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-md-12 text-center heading-section heading-section-white ftco-animate">
        <span class="subheading">{{counterSubText}}</span>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12 col-sm-2 col-xs-2">
        <div class="row">
          <div data-aos="fade-right" class="col-md-3 d-flex justify-content-center counter-wrap ftco-animate"
            *ngFor="let counter of counterList">
            <div class="block-18 text-center mat-elevation-z24 box m-3">
              <div class="text mt-4">
                <strong class="number">
                  {{counter.number}}
                </strong>
                <p>
                  <span>
                    {{counter.text}}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
              <h2 class="text-white">
                Got A Mobile Riddle For Us?
              </h2>
              <h3 class="text-white">
                Challenge accepted.
              </h3>
            <a href="javaScript:void(0)" routerLink="/contact" class="btn-grad text-center">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
