import { Component, Input, OnInit } from '@angular/core';
import {
  fadeInLeftOnEnterAnimation,
  fadeInOnEnterAnimation,
  fadeOutLeftOnLeaveAnimation,
  fadeOutOnLeaveAnimation
} from 'angular-animations';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeInLeftOnEnterAnimation(),
    fadeOutLeftOnLeaveAnimation()
  ]
})
export class BannerComponent implements OnInit {

  @Input() public typedData: string[] = [
    'Building Networks For People',
    'Building Products For StartUps',
    'Providing Services For College Students'
  ];

  @Input() public buttonText = 'Contact Us';
  @Input() public subText = `Capture user's attention and achieve Your Brand’s Goals through a Visually Stunning Website.`;

  imgUrl = '../../assets/img/banner/banner-home.png';

  ngOnInit() {

  }
}
