const counterList = [
    {
        number: '25+',
        text: 'Projects Completed',
        show: true
    },
    {
        number: '12+',
        text: 'Happy Clients',
        show: true
    },
    {
        number: '10 K+',
        text: 'Happy Users',
        show: true
    },
    {
        number: '35 M+',
        text: 'Lines Of Code',
        show: true
    },
    {
        number: '3 Years',
        text: 'Of experience',
        show: false
    },
    {
        number: '3 Years',
        text: 'Of experience',
        show: false
    }
];

export const getActiveCounterList = () => {
    return counterList.filter(service => service.show);
};
