<section class="error">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <div class="textHolder">
                    <img src="../../assets/img/logo.png" alt="">
                    <div class="textBody">
                        <h3>
                            {{text}}
                        </h3>
                        <h3>
                            {{text2}}
                        </h3>
                    </div>
                    <div class="center btnHolder mt-5">
                        <a href="javaScript:void(0)" routerLink="/" class="btn_2 center shadow m-2">
                        Home
                        </a>
                        <a href="javaScript:void(0)" routerLink="/contact" class="btn_2 center shadow m-2">
                        Contact Us
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>