<section class="ftco-section ftco-no-pt ftc-no-pb">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
            <div class="col-md-7 text-center heading-section ftco-animate">
                <h2 class="mb-4">Our Services</h2>
                <app-bar></app-bar>
                <!-- <p>We have a solid and dedicated team which works tirelessly to provide our clients, services of the best quality. We are efficent, punctual and do not compromise with quality.</p> -->
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 py-5">
                <img data-aos="zoom-in-down" src="../../assets/img/undraw_podcast_q6p7.svg" class="img-fluid" alt="">
                <div class="heading-section ftco-animate mt-5">
                    <h2 class="mb-4">Our Main Services</h2>
                    <p>We are offering any solution related to cloud, also responsive and dynamic websites, mobile apps, graphic designing, modern website designing, logo designing, game and animation.
                        <br>Apart form this we are expanding our services in AI, Machine Learning and IT security related to website, software and hardware infrastructure.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 py-5">
                <div class="row">
                    <div class="col-md-6 ftco-animate">
                        <div class="media block-6 services border text-center hvr-bounce-in hvr-bounce-to-top">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <!-- <span class="flaticon-cloud-computing"></span> -->
                                <i class="far fa-window-maximize"></i>
                            </div>
                            <div class="mt-3 media-body media-body-2">
                                <h3 class="heading">WebApp Development</h3>
                                <!-- <p>
                                    We offer responsive, innovative, agile & competitive services for custom software & application development. Our website works on any device: desktop, tablet or mobile.
                                </p> -->
                                <p>
                                    We offer responsive website for your company. Website works on any device: desktop, tablet or mobile.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ftco-animate">
                        <div class="media block-6 services border text-center hvr-bounce-in hvr-bounce-to-top">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <!-- <span class="flaticon-cloud"></span> -->
                                <i class="fas fa-mobile-alt"></i>
                            </div>
                            <div class="mt-3 media-body media-body-2">
                                <h3 class="heading">Mobile App Development</h3>
                                <p>Cross platform or native mobile app(Android + iOS) for your company.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ftco-animate">
                        <div class="media block-6 services border text-center hvr-bounce-in hvr-bounce-to-top">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <!-- <span class="flaticon-server"></span> -->
                                <i class="fas fa-palette"></i>
                            </div>
                            <div class="mt-3 media-body media-body-2">
                                <h3 class="heading">Designing</h3>
                                <p>Get a brand look for your website and show it to everyone.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ftco-animate">
                        <div class="media block-6 services border text-center hvr-bounce-in hvr-bounce-to-top">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <!-- <span class="flaticon-database"></span> -->
                                <i class="fas fa-bullhorn"></i>
                            </div>
                            <div class="mt-3 media-body media-body-2">
                                <h3 class="heading">Marketing & Advertising</h3>
                                <p>Get a brand look and promote your website.</p>
                            </div>
                        </div>
                    </div>
                    <div class="center">
                        <a href="javaScript:void(0)" routerLink="/services" class="btn_2 center shadow">{{buttonText}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>