<section class="ftco-section services-section bg-black tech-services-section">
  <div class="container">
    <div class="row justify-content-center mb-5 pb-3">
      <div class="col-md-7 text-center heading-section ftco-animate mt-3">
        <h2 class="mt-5 mb-3 text-light">We Are your Independent Design and Development Agency.</h2>
        <p>
          We are a full life-cycle software development company expert providing all kind of business services with
          quality.
        </p>
        <app-bar></app-bar>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 my-3" data-aos="fade-down" *ngFor="let service of servicesList">
        <div class="box mat-elevation-z24 text-center" *ngIf="service.show">
          <div class="icon d-flex align-items-center justify-content-center" data-aos="fade-up">
            <i *ngIf="service.isIcon" class="{{service.icon}}" aria-hidden="true"></i>
          </div>
          <div class="mt-3 media-body media-body-2" data-aos="fade-right">
            <h3 class="title">
              {{service.title}}
            </h3>
            <p class="description mb-3 text-light">
              {{service.description}}
            </p>
          </div>
        </div>
      </div>
      <div class="text-right col-12 mt-3">
        <a data-aos="fade-right" href="javaScript:void(0)" routerLink="/contact" class="btn-grad">
          Contact Us
        </a>
        <a *ngIf="showCheckAllServices" data-aos="fade-up" href="javaScript:void(0)" routerLink="/services" class="btn-grad">
          {{checkAllServices}}
        </a>
      </div>
    </div>
  </div>
</section>
