import { Component, OnInit } from '@angular/core';
import { fadeInLeftOnEnterAnimation, fadeOutLeftOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.scss'],
  animations: [
    fadeInLeftOnEnterAnimation(),
    fadeOutLeftOnLeaveAnimation()
  ]
})
export class WhyUsComponent implements OnInit {

  heading = 'We excel in delivering optimal solutions.';

  constructor() { }

  ngOnInit(): void {
  }

}
