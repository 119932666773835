import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  getContact(): Observable<any> {
    return this.firestore.collection('contact').get();
  }

  addContactData(body: any): Promise<any> {
    return this.firestore.collection(environment.database.contact).add(body);
  }

  sendPhoneOtp() {}
}
