<section class="ftco-section services-section bg-black">
  <div class="container">
    <div class="row justify-content-center mb-5 pb-3">
      <div class="col-md-7 text-center heading-section ftco-animate">
        <h2 class="mb-4 text-white">{{heading}}</h2>
        <p class="text-lght">We have a solid and dedicated team which works tirelessly to provide our clients,
          services of the best quality. We are efficent, punctual and do not compromise with quality.
        </p>
        <app-bar></app-bar>
      </div>
    </div>
    <div class="row">
      <div data-aos="fade-down" class="col-md-6 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-flex align-items-center hvr-grow hvr-bounce-to-left">
          <div class="icon d-flex align-items-center justify-content-center order-md-last">
            <span class="flaticon-cloud"></span>
          </div>
          <div class="media-body pl-4 pl-md-0 pr-md-4 text-md-right">
            <h3 class="heading text-white">Experienced Professionals</h3>
            <p class="mb-0 text-white">
              Having obtained the official & formal training in IT technology and technical fields,
              our team members are well knowledgeable and experienced.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-down" class="col-md-6 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-flex align-items-center hvr-grow hvr-bounce-to-right">
          <div class="icon d-flex align-items-center justify-content-center">
            <span class="flaticon-server"></span>
          </div>
          <div class="media-body pl-4">
            <h3 class="heading text-white">Cutting Edge Technology</h3>
            <p class="mb-0 text-white">
              We are offering services using the cutting edge technologies like Angular 9+,
              React, React Native, flutter, Node apis, Java Spring Boot, Node, Expres and a lot more.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-down" class="col-md-6 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-flex align-items-center hvr-grow hvr-bounce-to-left">
          <div class="icon d-flex align-items-center justify-content-center order-md-last">
            <span class="flaticon-customer-service"></span>
          </div>
          <div class="media-body pl-4 pl-md-0 pr-md-4 text-md-right">
            <h3 class="heading text-white">Clean Code & Well Documented</h3>
            <p class="text-white">
              You can find our code well organized and readable so less maintenance cost. Also we provided a
              comprehensive documentation.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-down" class="col-md-6 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-flex align-items-center hvr-grow hvr-bounce-to-right">
          <div class="icon d-flex align-items-center justify-content-center">
            <span class="flaticon-life-insurance"></span>
          </div>
          <div class="media-body pl-4">
            <h3 class="heading text-white">Secure and Reliable</h3>
            <p class="text-white">
              Security is our first priority. Because as more and more machines, websites are being logged in, cyber
              security has become a thing of paramount importance.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-down" class="col-md-6 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-flex align-items-center hvr-grow hvr-bounce-to-left">
          <div class="icon d-flex align-items-center justify-content-center order-md-last">
            <span class="flaticon-cloud-computing"></span>
          </div>
          <div class="media-body pl-4 pl-md-0 pr-md-4 text-md-right">
            <h3 class="heading text-white">On Time Delivey</h3>
            <p class="text-white">
              If we take a project, we get it done on or before time. You can count on that.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-down" class="col-md-6 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-flex align-items-center hvr-grow hvr-bounce-to-right">
          <div class="icon d-flex align-items-center justify-content-center">
            <!-- <span class="flaticon-settings"></span> -->
            <span class="flaticon-customer-service"></span>
          </div>
          <div class="media-body pl-4">
            <h3 class="heading text-white">24/7 Support</h3>
            <p class="text-white">We are always there and ready to solve your problems even after the development.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
