<footer class="footer_part bg-black">
  <div class="container-fluid">
    <!-- <div class="row">
      <div class="col-sm-8 col-md-4 col-lg-4">
        <div class="single_footer_part">
          <a href="javaScript:void(0)" routerLink="/" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" class="footer_logo_iner">
            <img src="../../assets/img/footer_logo.png" alt="#">
          </a>
          <p>
            We have a solid and dedicated team which works tirelessly to provide our clients, services of the best
            quality. We are efficent, punctual and do not compromise with quality.
          </p>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-2">
        <div class="single_footer_part">
          <h4>About Us</h4>
          <ul class="list-unstyled">
            <li><a href="">Managed Website</a></li>
            <li><a href="">Manage Reputation</a></li>
            <li><a href="">Power Tools</a></li>
            <li><a href="">Marketing Service</a></li>
            <li><a href="">Customer Service</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-2">
        <div class="single_footer_part">
          <h4>Quick Links</h4>
          <ul class="list-unstyled">
            <li><a href="">Store Hours</a></li>
            <li><a href="">Brand Assets</a></li>
            <li><a href="">Investor Relations</a></li>
            <li><a href="">Terms of Service</a></li>
            <li><a href="">Privacy & Policy</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-2">
        <div class="single_footer_part">
          <h4>My Account</h4>
          <ul class="list-unstyled">
            <li><a href="">Press Inquiries</a></li>
            <li><a href="">Media Directories</a></li>
            <li><a href="">Investor Relations</a></li>
            <li><a href="">Terms of Service</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2">
        <div class="single_footer_part">
          <h4>Resources</h4>
          <ul class="list-unstyled">
            <li><a href="">Application Security</a></li>
            <li><a href="">Software Policy</a></li>
            <li><a href="">Supply Chain</a></li>
            <li><a href="">Agencies Relation</a></li>
            <li><a href="">Manage Reputation</a></li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="row">
        <div class="col-8 offset-2">
            <div class="single_footer_part text-center">
                <a href="javaScript:void(0)" routerLink="/" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" class="footer_logo_iner">
                  <img src="../../assets/img/footer_logo.png" alt="#">
                </a>
                <p class="text-white">
                  We have a solid and dedicated team which works tirelessly to provide our clients, services of the best
                  quality. We are efficent, punctual and do not compromise with quality.
                </p>
              </div>
        </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-lg-8">
        <div class="copyright_text">
          <P class="text-white">
            Copyright &copy;
           {{currentYear}} All rights reserved | Made with <i class="ti-heart" aria-hidden="true"></i> by <a
              href="https://zipomega.com" target="_blank">ZipOmega</a>
          </P>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="footer_icon social_icon">
          <ul class="list-unstyled">
            <li><a href="#" class="single_social_icon"><i class="fab fa-facebook-f"></i></a></li>
            <!-- <li><a href="#" class="single_social_icon"><i class="fab fa-twitter"></i></a></li>
            <li><a href="#" class="single_social_icon"><i class="fas fa-globe"></i></a></li>
            <li><a href="#" class="single_social_icon"><i class="fab fa-behance"></i></a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- <script src="../../assets/js/jquery-1.12.1.min.js"></script> -->
<!-- popper js -->
<!-- <script src="../../assets/js/popper.min.js"></script> -->
<!-- bootstrap js -->
<!-- <script src="../../assets/js/bootstrap.min.js"></script> -->
<!-- easing js -->
<!-- <script src="../../assets/js/jquery.magnific-popup.js"></script> -->
<!-- swiper js -->
<!-- <script src="../../assets/js/swiper.min.js"></script> -->
<!-- swiper js -->
<!-- <script src="../../assets/js/masonry.pkgd.js"></script> -->
<!-- particles js -->
<!-- <script src="../../assets/js/owl.carousel.min.js"></script> -->
<!-- <script src="../../assets/js/jquery.nice-select.min.js"></script> -->
<!-- slick js -->
<!-- <script src="../../assets/js/slick.min.js"></script> -->
