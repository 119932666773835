<header class="main_menu" [ngClass]="{'single_page_menu' : !home}" [@fadeInDown]="navDown? 'fadeInDown' : 'none' "
  [@.disabled]='!navDown' id="navBar">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <nav class="navbar navbar-expand-lg navbar-light">
          <h1>
            <a class="navbar-brand main_logo" href="javaScript:void(0)" routerLink="/">
              <!-- <img src="../../assets/img/logo.png" alt="logo"> -->
              <span class="brand-name">
                ZipOmega
              </span>
            </a>
          </h1>
          <!-- <a class="navbar-brand single_page_logo" href="index.html"> <img src="../../assets/img/footer_logo.png" alt="logo"> </a> -->
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            (click)="toggleCollapsed()">
            <span class="menu_icon"></span>
          </button>

          <div class="collapse navbar-collapse main-menu-item" id="navbarSupportedContent"
            [ngClass]="{'collapse': collapsed, 'navbar-collapse': true}">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="javaScript:void(0)" routerLink="/" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">
                  Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javaScript:void(0)" routerLink="/services" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javaScript:void(0)" routerLink="/portfolio" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Portfolio</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="javaScript:void(0)" routerLink="/ready-made-scripts" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Ready Made Scripts</a>
              </li> -->
              <!-- <li class="nav-item">
                <a class="nav-link" href="javaScript:void(0)" routerLink="/products" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Products</a>
              </li> -->
              <!-- <li class="nav-item">
                                <a class="nav-link" href="javaScript:void(0)" routerLink="/Contest" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contest</a>
                            </li> -->
              <!-- <li class="nav-item">
                <a class="nav-link" href="javaScript:void(0)" routerLink="/resourses" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Resourses</a>
              </li> -->

              <!-- <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Blog
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="#"> blog</a>
                                    <a class="dropdown-item" href="#">Single blog</a>
                                </div>
                            </li> -->
              <!-- <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    pages
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                                    <a class="dropdown-item" href="elements.html">Elements</a>
                                </div>
                            </li> -->
              <li class="nav-item">
                <a class="nav-link" href="javaScript:void(0)" routerLink="/contact" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">
                  Contact Us</a>
              </li>
            </ul>
          </div>
          <a href="javaScript:void(0)" routerLink="/contact"
            class="d-none d-sm-block btn_1 home_page_btn btn-background">Support</a>
        </nav>
      </div>
    </div>
  </div>
</header>
