<!--::about_us part start::-->
<section class="review_part padding_bottom">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-6 col-lg-6">
                <div class="review_img">
                    <img src="../../assets/img/review_bg.png" alt="">
                </div>
            </div>
            <div class="col-md-6 col-lg-5">
                <div class="review_slider">
                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide>
                            <div class="review_part_text">
                                <h2>With efficiency to unlock more opportunities</h2>
                                <p>Saw shall light. Us their to place had creepeth day night great wher appear to. Hath, called, sea called, gathering wherein open make living Female itself gathering man. Waters and, two. Bearing. Saw she'd all let she'd
                                    lights abundantly blessed.</p>
                                <h3>Mitchel Jeferson, <span>CEO of softking</span> </h3>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="review_part_text">
                                <h2>With efficiency to unlock more opportunities</h2>
                                <p>Saw shall light. Us their to place had creepeth day night great wher appear to. Hath, called, sea called, gathering wherein open make living Female itself gathering man. Waters and, two. Bearing. Saw she'd all let she'd
                                    lights abundantly blessed.</p>
                                <h3>Mitchel Jeferson, <span>CEO of softking</span> </h3>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="review_part_text">
                                <h2>With efficiency to unlock more opportunities</h2>
                                <p>Saw shall light. Us their to place had creepeth day night great wher appear to. Hath, called, sea called, gathering wherein open make living Female itself gathering man. Waters and, two. Bearing. Saw she'd all let she'd
                                    lights abundantly blessed.</p>
                                <h3>Mitchel Jeferson, <span>CEO of softking</span> </h3>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>

                <!-- <div class="review_slider owl-carousel">
                    
                    <div class="review_part_text">
                        <h2>With efficiency to unlock more opportunities</h2>
                        <p>Saw shall light. Us their to place had creepeth day night great wher appear to. Hath, called, sea called, gathering wherein open make living Female itself gathering man. Waters and, two. Bearing. Saw she'd all let she'd lights
                            abundantly blessed.</p>
                        <h3>Mitchel Jeferson, <span>CEO of softking</span> </h3>
                    </div>
                    <div class="review_part_text">
                        <h2>With efficiency to unlock more opportunities</h2>
                        <p>Saw shall light. Us their to place had creepeth day night great wher appear to. Hath, called, sea called, gathering wherein open make living Female itself gathering man. Waters and, two. Bearing. Saw she'd all let she'd lights
                            abundantly blessed.</p>
                        <h3>Mitchel Jeferson, <span>CEO of softking</span> </h3>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
    <img src="../../assets/img/animate_icon/Ellipse_4.png" alt="" class="feature_icon_2 custom-animation2">
</section>
<!--::about_us part end::-->