import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-agile-steps',
  templateUrl: './agile-steps.component.html',
  styleUrls: ['./agile-steps.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AgileStepsComponent implements OnInit {

  @Input() title = 'Title';
  @Input() description = 'Description';
  @Input() imagePath = '../../assets/img/undraw_laravel_and_vue_59tp.svg'

  constructor() { }

  ngOnInit(): void {
  }

}
