import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ParticlesCounterConfig } from './ParticleJsCounterConfig';
import { ParticlesConfig } from './ParticlesConfig';


declare var particlesJS: any;
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(analytics: AngularFireAnalytics) {
    analytics.logEvent('Home_Page_Open_Event', {
      homePage: true,
    });
  }

  ngOnInit(): void {
    this.invokeParticles();
  }

  public invokeParticles(): void {
    particlesJS('particles-js', ParticlesConfig, () => { });
  }

}
