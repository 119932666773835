import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-highlight',
  templateUrl: './tech-highlight.component.html',
  styleUrls: ['./tech-highlight.component.scss']
})
export class TechHighlightComponent implements OnInit {

  servicesList = [
    {
      title: 'Web development',
      description: 'The best mobile app development solutions for diverse needs.',
      isReadMore: false,
      readMoreLink: '',
      isIcon: true,
      icon: 'far fa-window-maximize'
    },
    {
      title: 'Web development',
      description: 'The best mobile app development solutions for diverse needs.',
      isReadMore: false,
      readMoreLink: '',
      isIcon: true,
      icon: 'far fa-window-maximize'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
