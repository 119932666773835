<div class="agile">
  <div class="row">
    <div data-aos="fade-right" class="col-12 col-sm-12 col-md-6">
      <img [src]="imagePath" class="img-fluid mt-5" alt="">
    </div>
    <div class="col-12 col-sm-12 col-md-6 mt-3">
      <div class="box mat-elevation-z24 w-100 h-100" data-aos="fade-down">
          <div class="p-4">
            <h3 class="title text-white">
                {{title}}
              </h3>
              <p class="description text-white">
                {{description}}
              </p>
              <div class="text-center mt-4">
                <a href="javaScript:void(0)" routerLink="/services" class="btn-grad text-center">
                  Contact Us
                </a>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
